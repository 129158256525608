@import 'styles/_helpers';

.video-list {
    $this: &;
    $easing-enter: 600ms $ease-out-quart;
    $easing-exit: 600ms $ease-in-quart;
    margin-top: $spacing;
    margin-bottom: $spacing;

    &__list {
        display: grid;
        grid-gap: $gutter;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: $spacing;

        @include min-width(xxl) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__link {
        @include font-size(small);
        display: block;
        width: 100%;
        height: 100%;
        padding: $spacing--xsmall;
        border-radius: $border-radius;
        background-color: color(neutral, xdark);
        text-align: center;

        @include min-width(lg) {
            padding: $spacing--small;
            border-radius: $border-radius--large;
        }

        transition: background-color 200ms $ease-out-quart;

        &:hover,
        &:focus {
            //background-color: color(primary);

            #{$this}__topic {
                background-color: color(primary, light);
            }
        }

        &--square {
            @include max-width(1500px) {
                padding-bottom: $spacing--small;
            }

            #{$this}__types {
                width: 68%;
                margin-top: 5%;

                @include min-width(md) {
                    width: 60%;
                }

                @include min-width(1500px) {
                    width: 73%;
                }
            }

            #{$this}__title {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 1.2em;
                color: $color-light;
            }
        }
    }

    &__types {
        width: 80%;
        margin: $spacing--large auto auto;

        @include min-width(md) {
            margin: $spacing auto auto;
            width: 90%;
        }
    }

    &__image {
        object-fit: cover;
        z-index: 0;
        border-radius: inherit;
    }

    &__overlay {
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0.8;
        mix-blend-mode: multiply;
        border-radius: inherit;
        background: linear-gradient(rgba(black, 0.65) 50%, rgba(black, 1));
    }

    &__item {
        position: relative;
        display: flex;
        justify-content: center;
        animation: entry-enter 600ms $ease-in-out-quint--custom backwards;
        border-radius: inherit;
        @include animation-delay(10, 50ms);
        aspect-ratio: 4/3;

        &--square {
            aspect-ratio: 1/1;
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;
        color: #fff;
        z-index: 12;
        position: relative;
    }

    &__main-title {
        margin-top: $section-spacing;
        margin-bottom: $spacing;
        z-index: 99;
    }

    &__title {
        @include font(base, bold);
        @include font-size(preamble-small);
        text-shadow: 0 1px 10px rgba(black, 0.5);
        margin-top: auto;

        @supports (display: -webkit-box) {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        &--long-word {
            @include hyphens;
        }
    }

    &__tag {
        @include text-overflow;
        @include font-size(small);
        max-width: calc(100% - 20px);
        z-index: 12;
        width: fit-content;
        justify-self: flex-end;
        align-self: flex-end;
        top: 12px;
        right: 12px;
        padding: 4px 10px 3px;
        border-radius: $border-radius--small;
        background-color: color(neutral, xlight);
        color: $color-text;
        margin-bottom: 10px;

        @include min-width(md) {
            padding: 6px 12px 5px;
            top: $spacing--small;
            right: $spacing--small;
        }
    }

    &__play {
        $size: 54px;
        position: absolute;
        z-index: 11;
        top: calc(50% - #{$size * 0.5});
        left: calc(50% - #{$size * 0.5});
        display: flex;
        justify-content: center;
        align-items: center;
        width: $size;
        height: $size;
        padding-left: 1px;
        border-radius: $border-radius--small;
        color: $color-text;
        background-color: color(neutral, xlight);
        box-shadow: 0 0 20px rgba(black, 0.2);
    }
}

@keyframes entry-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
    }
}
