@import 'styles/_helpers';

.illustration-grid {
    $this: &;
    display: grid;
    align-self: center;
    flex-shrink: 0;
    flex-grow: 0;

    position: relative;

    &__images {
        position: absolute;
        display: grid;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &--1 {
            grid-template-areas: 'image1';
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            align-items: center;
            justify-content: center;

            #{$this}__image {
                position: relative;
                width: calc(68% + (var(--compansation) * 0.4));
                margin: auto;

                #{$this}--hover & {
                    transform: rotate(calc((var(--right) * 0.7) + 1deg));
                }
            }
        }

        &--2 {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-template-areas:
                'image1 .'
                '. image2';

            #{$this}__image {
                width: calc(116% + var(--compansation));

                &--1 {
                    top: 6%;
                    left: 6%;
                    transform: rotate(var(--left));

                    #{$this}--hover & {
                        transform: translate(-4%, -4%) rotate(calc(var(--left) * 1.2));
                    }
                }

                &--2 {
                    bottom: 6%;
                    right: 6%;
                    transform: rotate(var(--right));

                    #{$this}--hover & {
                        transform: translate(4%, 4%) rotate(calc(var(--right) * 1.2));
                    }
                }
            }
        }

        &--3 {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            grid-template-areas:
                'image1 image2'
                'image1 image3';

            #{$this}__image {
                width: calc(116% + var(--compansation));

                &--1 {
                    top: 12%;
                    left: 0;
                    transform: rotate(var(--left));

                    #{$this}--hover & {
                        transform: translate(-4%, -3%) rotate(calc(var(--left) * 1.1));
                    }
                }

                &--2 {
                    top: -2%;
                    right: -5%;
                    transform: rotate(var(--right));

                    #{$this}--hover & {
                        transform: translate(3%, -4%) rotate(calc(var(--right) * 1.1));
                    }
                }

                &--3 {
                    bottom: -2%;
                    right: -1%;
                    transform: rotate(var(--right));

                    #{$this}--hover & {
                        transform: translate(3%, 3%) rotate(calc(var(--right) * 1.1));
                    }
                }
            }
        }
    }

    &__cell {
        position: relative;
        grid-area: image;

        &--1 {
            grid-area: image1;
        }

        &--2 {
            grid-area: image2;
        }

        &--3 {
            grid-area: image3;
        }
    }

    &__image {
        --compansation: 0%;
        --left: 0deg;
        --right: 0deg;
        position: absolute;
        transition: transform 500ms $ease-out-quart;
        z-index: 2;

        &--video {
            --compansation: 26%;
            --right: -4.5deg;
            --left: -4deg;
        }

        &--home {
            --compansation: 20%;
            --right: 5deg;
            --left: -5deg;
        }

        &--diskusjon {
            --compansation: 4%;
            z-index: 4;
        }

        &--game {
            --compansation: 13%;
            --right: 1deg;
            // z-index: 4;
        }

        &--quiz {
            z-index: 3;
        }

        &--submission {
            z-index: 1;
            --compansation: 6%;
            --left: -4deg;
        }
    }

    &__aspect {
        display: block;
        width: 100%;
    }

    &--hover {
        #{$this}__image {
            will-change: transform;
        }
    }
}
