@import 'styles/_helpers';

.article-entry {
    $this: &;
    width: 100%;

    &__list {
        display: grid;
        grid-gap: $gutter;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: $spacing;
        max-width: 100%;

        @include min-width(xxl) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__sub-title {
        @include font(base, normal);
        @include font-size(base);
        @include line-clamp(2);
        margin-bottom: 0.3em;
    }

    &__item {
        display: flex;
        flex-direction: column;
        padding: $spacing--xsmall;
        border-radius: $border-radius--large;
        background-color: color(neutral, light);
        overflow: hidden;

        &:hover,
        &:focus:focus-visible {
            background-color: color(neutral);
        }

        animation: entry-enter 600ms $ease-in-out-quint--custom both;
        @include animation-delay(10, 50ms);

        aspect-ratio: 1/1;

        &--no-image {
            justify-content: center;
            align-items: center;
        }

        &--promoted {
            background-color: color(primary, light);

            &:hover,
            &:focus:focus-visible {
                background-color: color(primary);
            }

            #{$this}__sub-title {
                @include font(base, bold);
                @include font-size(preamble-small);
            }
        }
    }

    &__illustration {
        aspect-ratio: 3/2;
        width: 100%;
        object-fit: contain;
        object-position: center bottom;
        border-radius: $border-radius;

        @include min-width(1493px) {
            aspect-ratio: 4/3;
        }
    }

    &__content {
        text-align: center;
        padding: 0.5rem 1rem 0;

        @include min-width(md) {
            padding: 0.75rem 1rem;
        }
    }

    &__meta {
        @include font-size(small);
        color: $color-muted;

        @supports (display: -webkit-box) {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
    }

    &__image-wrapper {
        display: flex;
        width: 100%;
    }

    &__image {
        aspect-ratio: 3/2;
        width: 100%;
        object-fit: cover;
        border-radius: $border-radius;
        max-width: 100%;
    }
}

@keyframes entry-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}
