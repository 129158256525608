@import 'styles/_helpers';

.lesson-entry {
    $this: &;
    $easing-enter: 600ms $ease-out-quart;
    $easing-exit: 600ms $ease-in-quart;
    margin-top: $spacing;
    margin-bottom: $spacing;

    &__list {
        display: grid;
        grid-gap: $gutter;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: $spacing;

        @include min-width(xxl) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__link {
        @include font-size(small);
        display: block;
        height: 100%;
        padding: $spacing--small;
        border-radius: $border-radius;
        background-color: color(primary, light);
        text-align: center;

        @include min-width(lg) {
            padding: $spacing;
            border-radius: $border-radius--large;
        }

        transition: background-color 200ms $ease-out-quart;

        &:hover,
        &:focus {
            background-color: color(primary);

            #{$this}__topic {
                background-color: color(primary, light);
            }
        }

        &--square {
            aspect-ratio: 1/1;

            @include max-width(1500px) {
                padding-bottom: $spacing--small;
            }

            #{$this}__types {
                width: 55%;
                margin-top: 18%;

                @include min-width(md) {
                    margin-top: 7%;
                    width: 60%;
                }
            }

            #{$this}__title {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 1.2em;
            }

            #{$this}__illustration {
                margin-top: -6%;
                max-height: 80%;
            }
        }
    }

    &__types {
        width: 80%;
        margin: $spacing--large auto auto;

        @include min-width(md) {
            margin: $spacing auto auto;
            width: 90%;
        }
    }

    &__illustration {
        position: relative;
        width: 80%;
        padding-bottom: calc(100% / 1.1);
        margin: $spacing auto auto;
    }

    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        margin-bottom: auto;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__item {
        position: relative;
        animation: entry-enter 600ms $ease-in-out-quint--custom both;
        @include animation-delay(10, 50ms);
    }

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__duration {
        margin: $spacing--small 0 10px;
    }

    &__main-title {
        margin-top: $section-spacing;
        margin-bottom: $spacing;
    }

    &__title {
        @include font(base, bold);
        @include font-size(base);
        margin-top: $spacing;

        @supports (display: -webkit-box) {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        @include min-width(lg) {
            @include font-size(sub-title);
        }

        &--long-word {
            @include hyphens;
        }
    }

    &__topic {
        @include text-overflow;
        @include font-size(small);
        position: absolute;
        max-width: calc(100% - 20px);
        top: 12px;
        right: 12px;
        padding: 4px 10px 3px;
        border-radius: $border-radius--small;
        background-color: color(primary);
        transition: background-color 200ms $ease-out-quart;

        @include min-width(md) {
            padding: 6px 12px 5px;
            top: $spacing--small;
            right: $spacing--small;
        }
    }
}

@keyframes entry-enter {
    from {
        clip-path: inset(100% 100% 0 0 round #{$border-radius--small});
    }

    to {
        clip-path: inset(0 0 0 0 round #{$border-radius--small});
    }
}
