@import 'styles/_helpers';

.section {
    $this: &;

    &:not(:last-child) {
        margin-bottom: $section-spacing--medium;
    }

    &__title {
        @include font-size(title);
        margin-bottom: $spacing;
        animation: fade-in 500ms $ease-in-out-quint--custom both;

        &--spaceless {
            margin: 0;
        }
    }

    &__header {
        display: grid;
        grid-gap: $gutter;
        align-items: baseline;
        margin-bottom: $section-spacing--small;

        @include min-width(lg) {
            grid-template-columns: 1fr 1fr;
        }
    }

    &__preamble {
        @include font-size(sub-title);

        animation: fade-in 500ms $ease-in-out-quint--custom both;
    }

    &--spaced {
        &:not(:last-child) {
            margin-bottom: $section-spacing;
        }
    }

    &--grid {
        #{$this}__content {
            display: grid;
            grid-gap: $gutter;

            @include min-width(lg) {
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    &--stacked {
        #{$this}__content {
            display: grid;
            grid-gap: $gutter;

            @include min-width(500px) {
                grid-template-columns: 1fr 1fr;
            }

            @include min-width(lg) {
                grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
            }
        }
    }

    &--list {
        #{$this}__header {
            display: flex;
            flex-direction: column;
            gap: 0.2rem;
            margin-bottom: $spacing;
        }

        #{$this}__title {
            @include font-size(sub-title);
        }

        #{$this}__preamble {
            @include font-size(base);
            color: $color-muted;
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}
